import React from "react";
import aboutEntries from "./aboutEntries";

const AboutItem = ({ aboutTitle, aboutDescription, aboutImage }) => {
  return (
    <>
      <li style={{backgroundImage: `url(${aboutImage.src})`}}>
        <div className="accordion-content glass mg-vertical-16">
          <h3 className="fs-5 ff-medium lh-heading">
            {aboutTitle}
          </h3>
          <p className="fs-7 ff-light lh-body">
            {aboutDescription}
          </p>
        </div>
      </li>
    </>
  );
};

export default AboutItem;
