import React from 'react'

const NavClose = () => {
  return (
    <>
      <img src="../../img/navigation/close.svg" alt="Custom Close" />
    </>
  )
}

export default NavClose
