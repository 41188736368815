import { Row, Col } from "antd";
import "./contact.css";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <>
      <section id="kontakt" className="homepage-contact padding-m">
        <div className="section-container">
          <Row className="contact-header-row">
            <Col span={24} className="align-center mg-vertical-24">
              <h2 className="fs-2 clr-primary-main ff-semibold">Kontakt</h2>
              <p className="fs-6 ff-light lh-body">
                Vielen Dank für Ihr Interesse an dem Schulungsprogramm. Bei
                Fragen zu gelisteten oder ungelisteten Themen oder für eine
                Terminvereinbarung melden Sie sich gerne über das
                Kontaktformular bei mir. Gerne lasse ich Ihnen weitere
                Informationen zukommen und freue mich über eine Zusammenarbeit.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={18} className="form-container-col">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Contact;
