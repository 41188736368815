import { Row, Col } from "antd";
import "./about.css";
import aboutEntries from "./aboutEntries";
import AboutItem from "./AboutItem";

const About = () => {
  return (
    <>
      <section id="ueber-mich" className="homepage-about padding-m">
        <div className="section-container">
          <Row>
            <Col span={24}>
              <h2 className="fs-2 clr-primary-main ff-semibold">Über mich</h2>
            </Col>
          </Row>
          <div className="accordion">
            <ul>
              {aboutEntries.map((aboutEntry) => (
                <AboutItem
                  key={aboutEntry.id}
                  aboutTitle={aboutEntry.aboutTitle}
                  aboutDescription={aboutEntry.aboutDescription}
                  aboutImage={aboutEntry.aboutImage}
                />
              ))}
              {/* <li>
                <div className="accordion-content glass mg-vertical-16">
                  <h3 className="fs-5 ff-medium lh-heading">
                    Abschluss M.Sc. Mechatronik an der FAU Erlangen-Nürnberg
                  </h3>
                  <p className="fs-7 ff-light lh-body">
                    Vivamus magna justo, lacinia eget consectetur sed, convallis
                    at tellus. Donec sollicitudin molestie malesuada. Curabitur
                    arcu erat, accumsan id imperdiet et, porttitor at sem.
                  </p>
                </div>
              </li>
              <li>
                <div className="accordion-content glass mg-vertical-16">
                  <h3 className="fs-5 ff-medium lh-heading">
                    Selbstständiger Dozent für technische Weiterbildung
                  </h3>
                  <p className="fs-7 ff-light lh-body">
                    Vivamus magna justo, lacinia eget consectetur sed, convallis
                    at tellus. Donec sollicitudin molestie malesuada. Curabitur
                    arcu erat, accumsan id imperdiet et, porttitor at sem.
                  </p>
                </div>
              </li>
              <li>
                <div className="accordion-content glass mg-vertical-16">
                  <h3 className="fs-5 ff-medium lh-heading">
                    Passionierter Bastler
                  </h3>
                  <p className="fs-7 ff-light lh-body">
                    Vivamus magna justo, lacinia eget consectetur sed, convallis
                    at tellus. Donec sollicitudin molestie malesuada. Curabitur
                    arcu erat, accumsan id imperdiet et, porttitor at sem.
                  </p>
                </div>
              </li>
              <li>
                <div className="accordion-content glass mg-vertical-16">
                  <h3 className="fs-5 ff-medium lh-heading">
                    Leidenschaft für Oldtimer Motorräder
                  </h3>
                  <p className="fs-7 ff-light lh-body">
                    Vivamus magna justo, lacinia eget consectetur sed, convallis
                    at tellus. Donec sollicitudin molestie malesuada. Curabitur
                    arcu erat, accumsan id imperdiet et, porttitor at sem.
                  </p>
                </div>
              </li>
              <li>
                <div className="accordion-content glass mg-vertical-16">
                  <h3 className="fs-5 ff-medium lh-heading">
                    Begeisterung für Elektromobilität und Leistungselektronik im
                    KFZ Bereich
                  </h3>
                  <p className="fs-7 ff-light lh-body">
                    Vivamus magna justo, lacinia eget consectetur sed, convallis
                    at tellus. Donec sollicitudin molestie malesuada. Curabitur
                    arcu erat, accumsan id imperdiet et, porttitor at sem.
                  </p>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
