import { useParams } from "react-router-dom";
import eventsEntries from "./eventsEntries";
import "./eventPage.css";

function EventPage(props) {
  const { eventId } = useParams();
  // fetch event details using the id
  const event = eventsEntries.find((event) => event.eventId === eventId);

  return (
    <>
      <section className="event-article">
        <div className="section-container">
          <div className="article-head mg-vertical-32">
            <h1 className="ff-semibold fs-2">{event.title}</h1>

            <img src={event.featuredImage.src} alt={event.featuredImage.alt} />

            <p className="fs-6-fixed ff-light">
              {event.date} | {event.author}
            </p>
            <hr className="divider-center" />
          </div>
          <div className="article-body">
            {event.content.map((section) => (
              <div
                className="mg-vertical-32 article-section"
                key={section.title}
              >
                {section.title && (
                  <h2 className="fs-3 ff-medium">{section.title}</h2>
                )}
                {section.elements.map((element) => {
                  if (element.type === "paragraph") {
                    return (
                      <p
                        className="fs-6-fixed ff-light lh-xl"
                        key={element.value}
                      >
                        {element.value}
                      </p>
                    );
                  } else if (element.type === "image") {
                    return (
                      <img
                        key={element.value.src}
                        src={element.value.src}
                        alt={element.value.alt}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default EventPage;
