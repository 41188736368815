import "./navigation.css";
import { Modal } from "antd";
import { useState } from "react";
import NavClose from "./NavClose";
import { HashLink as Link } from 'react-router-hash-link'; 

function getModalContainer() {
  return document.getElementById("mobile-nav-container");
}

const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <section className="header" id="home">
        <div className="section-container">
          <ul className="nav nav-desktop">
            <li className="nav-item">
              <Link className="fs-7-fixed ff-regular" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="fs-7-fixed ff-regular" to="/#spezialgebiete" smooth>
                Spezialgebiete
              </Link>
            </li>
            <li className="nav-item">
              <Link className="fs-7-fixed ff-regular" to="/#mein-kursangebot" smooth>
                Kurse
              </Link>
            </li>
            <li className="nav-item logo">
              <Link to="/">
                <img
                  src="../img/navigation/logo.svg"
                  alt="Let's Power It Logo"
                />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="fs-7-fixed ff-regular" to="/#ueber-mich" smooth>
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#kontakt" smooth className="fs-7-fixed ff-regular">
                Kontakt
              </Link>
            </li>
            <li className="nav-item">
              <Link className="fs-7-fixed ff-regular" to="/events">
                Events
              </Link>
            </li>
          </ul>
          <ul className="nav nav-mobile">
            <li className="nav-item logo">
              <Link to="/">
                <img
                  src="../img/navigation/logo.svg"
                  alt="Let's Power It Logo"
                />
              </Link>
            </li>
            <li className="nav-item hamburger">
              <Link onClick={showModal}>
                <img src="../img/navigation/hamburger.svg" />
              </Link>
            </li>
          </ul>
          <div className="mobile-nav-container" id="mobile-nav-container"></div>
          <Modal
            getContainer={getModalContainer}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            closeIcon={<NavClose />}
          >
            <ul className="nav-items-mobile">
              <li className="nav-item">
                <Link onClick={handleCancel} className="ff-regular" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleCancel}
                  className="ff-regular"
                  to="/#spezialgebiete"
                  smooth
                >
                  Spezialgebiete
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleCancel}
                  className="ff-regular"
                  to="/#mein-kursangebot"
                  smooth
                >
                  Kurse
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleCancel}
                  className="ff-regular"
                  to="/#ueber-mich"
                  smooth
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleCancel}
                  to="/#kontakt"
                  className="ff-regular"
                  smooth
                >
                  Kontakt
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleCancel}
                  className="ff-regular"
                  to="/events"
                >
                  Events
                </Link>
              </li>
            </ul>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Navigation;
