import {
  Row,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Cascader,
  message,
} from "antd";
import { useRef, useState } from "react";
import "./contactForm.css";
import courseHierarchy from "../courses/courseHierarchy";
import { useEffect } from "react";

//cascaderOptions logic
const cascaderOptions = [];

// loop through each object in courseHierarchy array
courseHierarchy.forEach((obj) => {
  // get key (mainTopic) and value (array of courses)
  const [mainTopic, courses] = Object.entries(obj)[0];

  // create new object for Cascader option
  const cascaderOption = {
    value: mainTopic,
    label: mainTopic,
    children: [],
  };

  // loop through each course and add to children array
  courses.forEach((course) => {
    cascaderOption.children.push({
      value: course.id,
      label: course.courseTitle,
    });
  });

  // add cascaderOption to cascaderOptions array
  cascaderOptions.push(cascaderOption);
});

const { RangePicker } = DatePicker;
const { TextArea } = Input;

// email validation
const emailValidationRules = [
  {
    type: "email",
    message: "Bitte geben Sie Ihre Email-Adresse an.",
  },
  {
    required: true,
    message: "Bitte geben Sie Ihre Email-Adresse an.",
  },
];

// participants validation
const participantsValidationRules = [
  {
    pattern: /^[0-9]*$/,
    message: "Bitte geben Sie eine Zahl an.",
  },
  {
    required: true,
    message: "Bitte geben Sie die Teilnehmerzahl an.",
  },
];

// participants validation
const captchaValidationRules = [
  {
    required: true,
    message: "Bitte geben Sie den Sicherheitscode ein.",
  },
];

const ContactForm = () => {
  // show course relevant fields on select
  const [selectedOption, setSelectedOption] = useState("");
  const [captchaImage, setCaptchaImage] = useState(null);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  // state for checking if form has been submitted (used to load new captcha img)
  const [formSubmitted, setFormSubmitted] = useState(false);
  // state for storing the error message related to the captcha field
  const [captchaError, setCaptchaError] = useState(false);

  const formRef = useRef(null);

  // create captcha image on site load and on form submission
  useEffect(() => {
    getCaptchaImage();
  }, [formSubmitted]);

  function handleSelectChange(value) {
    setSelectedOption(value);
  }

  const getCaptchaImage = async () => {
    const response = await fetch("/captcha.php");
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setCaptchaImage(url);
  };

  const handleCaptchaChange = (event) => {
    setCaptchaAnswer(event.target.value);
  };

  // Form functionality logic
  const onFinish = (formData) => {
    fetch("/send-email.php", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Display success message and reset form
          message.success("Vielen Dank für Ihre Anfrage!");
          formRef.current.resetFields();
          setCaptchaError(null);
          setFormSubmitted(!formSubmitted);
        } else {
          // Update captcha error state and reset the captcha field only
          setCaptchaError(true);
          formRef.current.setFieldsValue({ captcha: "" });
          getCaptchaImage();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Form
        name="contact-form"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
      >
        <Row gutter={24} className="form-content-row name-row">
          <Col xs={24} lg={12}>
            <Form.Item
              name="vorname"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihren Vornamen an.",
                },
              ]}
            >
              <Input placeholder="Vorname*" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="nachname"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihren Nachnamen an.",
                },
              ]}
            >
              <Input placeholder="Nachname*" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: "none" }}>
          <Col span={24}>
            <Form.Item name="address">
              <Input type="checkbox" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="form-content-row company-row">
          <Col span={24}>
            <Form.Item name="firma">
              <Input placeholder="Firma" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="form-content-row contact-row">
          <Col xs={24} lg={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie eine Telefonnummer an.",
                },
              ]}
            >
              <Input placeholder="Telefon*" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="email" rules={emailValidationRules}>
              <Input placeholder="E-Mail*" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="form-content-row request-type-row">
          <Col span={24}>
            <Form.Item
              name="request-type"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie die Art der Anfrage an.",
                },
              ]}
            >
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
                placeholder="Art der Anfrage*"
                style={{
                  width: "100%",
                }}
                options={[
                  {
                    value: "allgemeine-anfrage",
                    label: "Allgemeine Anfrage",
                  },
                  {
                    value: "kursanfrage",
                    label: "Kursanfrage",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        {selectedOption === "kursanfrage" && (
          <div className="course-relevant-fields" style={{ display: "block" }}>
            <Row gutter={24} className="form-content-row course-cascader">
              <Col span={24}>
                <Form.Item
                  name="course-cascader"
                  rules={[
                    {
                      required: true,
                      message: "Bitte wählen Sie Ihren Kurs aus.",
                    },
                  ]}
                >
                  <Cascader
                    options={cascaderOptions}
                    placeholder="Kurs*"
                    allowClear={false}
                    expandTrigger="hover"
                    displayRender={(labels) => labels.join(" > ")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="form-content-row participants-row">
              <Col span={24}>
                <Form.Item
                  name="participants"
                  rules={participantsValidationRules}
                >
                  <Input placeholder="Teilnehmerzahl*" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="form-content-row date-picker-row">
              <Col span={24}>
                <Form.Item
                  name="desired-date"
                  rules={[
                    {
                      required: true,
                      message: "Bitte geben Sie das gewünschte Datum an.",
                    },
                  ]}
                >
                  <RangePicker
                    format={"DD.MM.YYYY"}
                    placeholder={["Start-Datum*", "End-Datum*"]}
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className="form-content-row location-row">
              <Col span={24}>
                <Form.Item
                  name="desired-location"
                  rules={[
                    {
                      required: true,
                      message: "Bitte geben Sie die gewünschte Location an.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Location*"
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: "own-rooms",
                        label: "In eigenen Räumen",
                      },
                      {
                        value: "away-possible",
                        label: "Auswärts möglich",
                      },
                      {
                        value: "indifferent",
                        label: "Egal wo",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={24} className="form-content-row message-row">
          <Col span={24}>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihre Nachricht ein.",
                },
              ]}
            >
              <TextArea rows={5} placeholder="Nachricht*" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="form-content-row captcha-row">
          <Col xs={24} lg={8}>
            <Form.Item name="captcha" rules={captchaValidationRules}>
              <Input
                id="captcha"
                name="captcha"
                value={captchaAnswer}
                placeholder="Sicherheitscode*"
                onChange={handleCaptchaChange}
              />
            </Form.Item>
            {/* error shows when the captcha entered is wrong */}
            {captchaError && (
              <p className="fs-7-fixed ff-regular captcha-error">
                Bitte geben Sie den Code erneut ein.
              </p>
            )}
          </Col>
          <Col xs={24} lg={16} className="mg-horizontal-32">
            {captchaImage && <img src={captchaImage} alt="Captcha" />}
            <button
              class="btn btn-flat btn-submit"
              type="button"
              onClick={getCaptchaImage}
            >
              Neuer Code
            </button>
          </Col>
        </Row>

        <Row gutter={24} className="form-content-row submit-row btn-submit">
          <Col span={24}>
            <Form.Item name="submit">
              <button className="btn btn-regular btn-submit">Senden</button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
