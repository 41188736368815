import "./noCourse.css";

const NoCourse = () => {
  return (
    <>
      <div className="no-course-container">
        <div className="glass">
            <p className="fs-6-fixed ff-regular">
                Diesen Kurs muss ich erst noch erfinden
            </p>
        </div>
      </div>
    </>
  )
}

export default NoCourse
