import { Select } from "antd";
import courseEntries from "./courseEntries";
import "./courseFilters.css";

const CourseFilters = ({
  onSelectMainTopic,
  selectedMainTopic,
  setSelectedMainTopic,
  onSelectCourseType,
  selectedCourseType,
  setSelectedCourseType,
}) => {
  // Main topic logic
  const uniqueMainTopics = Array.from(
    new Set(courseEntries.flatMap((course) => course.mainTopics))
  );
  const mainTopicOptions = [
    <Select.Option key="all" value={null}>
      Alle Themen
    </Select.Option>,
    ...uniqueMainTopics.map((mainTopic) => (
      <Select.Option key={mainTopic} value={mainTopic}>
        {mainTopic}
      </Select.Option>
    )),
  ];

  // Course type logic
  const uniqueCourseTypes = Array.from(
    new Set(courseEntries.map((course) => course.courseType))
  );
  const courseTypeOptions = [
    <Select.Option key="all" value={null}>
      Alle Kursformen
    </Select.Option>,
    ...uniqueCourseTypes.map((courseType) => (
      <Select.Option key={courseType} value={courseType}>
        {courseType}
      </Select.Option>
    )),
  ];

  const handleMainTopicChange = (value) => {
    setSelectedMainTopic(value);
    onSelectMainTopic(value);
  };

  const handleCourseTypeChange = (value) => {
    setSelectedCourseType(value);
    onSelectCourseType(value);
  };

  return (
    <>
      <div className="filter-container">
        <Select
          placeholder="Thema"
          value={selectedMainTopic}
          onChange={handleMainTopicChange}
          className="topic-select-filter"
        >
          {mainTopicOptions}
        </Select>
        <Select
          placeholder="Kursform"
          value={selectedCourseType}
          onChange={handleCourseTypeChange}
          className="course-type-select-filter"
        >
          {courseTypeOptions}
        </Select>
      </div>
    </>
  );
};

export default CourseFilters;
