import { v4 as uuid4 } from "uuid";

const aboutEntries = [
  {
    // about Item 1
    id: uuid4(),
    aboutTitle: "Abschluss M.Sc. Mechatronik an der FAU Erlangen-Nürnberg",
    aboutDescription:
      "Master of Science Abschluss in Mechatronik an der Friedrich-Alexander-Universität Erlangen-Nürnberg mit Spezialisierungen auf Leistungselektronik und Cybersecurity.",
    aboutImage: {
      src: "../../img/homepage/about/master.webp",
      alt: "Abschluss M.Sc. an der FAU",
    },
  },
  {
    // about Item 2
    id: uuid4(),
    aboutTitle: "Selbstständiger Dozent für technische Weiterbildung",
    aboutDescription:
      "Wissen muss geteilt werden, damit es sich vermehrt. Technische Kurse und praktisch orientierte Bootcamps erweitern nicht nur das Wissen aller Teilnehmenden, sondern dürfen auch Spaß machen.",
    aboutImage: {
      src: "../../img/homepage/about/device.webp",
      alt: "Selbstständiger Dozent für technische Weiterbildung",
    },
  },
  {
    // about Item 3
    id: uuid4(),
    aboutTitle: "Passionierter Bastler",
    aboutDescription:
      "Leidenschaftlicher Tüftler und Bastler. Besonders die Verbindung aus Elektrotechnik und Informatik zur Realisierung von Projekten rund um die Vernetzung und Automatisierung sind eine große Motivation.",
    aboutImage: {
      src: "../../img/homepage/about/tinkerer.webp",
      alt: "Passionierter Bastler",
    },
  },
  {
    // about Item 4
    id: uuid4(),
    aboutTitle: "Leidenschaft für Oldtimer",
    aboutDescription:
      "Nicht nur neue Technologien sind von Interesse, auch die \"Ursprünge\" sind wichtig um technische Zusammenhänge nachvollziehen zu können. Gerade entwicklungstechnische Meilensteine der Kraftfahrzeuggeschichte haben einen besonderen Reiz und meist auch mehr \"Charakter\" als durchoptimierte Neufahrzeuge.",
    aboutImage: {
      src: "../../img/homepage/about/classics.webp",
      alt: "Leidenschaft für Oldtimer",
    },
  },
  {
    // about Item 5
    id: uuid4(),
    aboutTitle: "Begeisterung für Elektromobilität und Leistungselektronik im KFZ Bereich",
    aboutDescription:
      "Zukunftsweisende Antriebskonzepte sind nicht nur technische Herausforderungen, welche gerne angenommen werden, sondern bieten auch eine gute Plattform für Innovationen sowie Spaß an der Mobilität.",
    aboutImage: {
      src: "../../img/homepage/about/electric.webp",
      alt: "Begeisterung für Elektromobilität und Leistungselektronik im KFZ Bereich",
    },
  },
];

export default aboutEntries;
