import About from "../components/homepage/about/About";
import Contact from "../components/homepage/contact/Contact";
import Courses from "../components/homepage/courses/Courses";
import Hero from "../components/homepage/hero/Hero";
import Topics from "../components/homepage/topics/Topics";

const Homepage = () => {
  return (
    <>
      <Hero />
      <Topics />
      <Courses />
      <About />
      <Contact />
    </>
  );
};

export default Homepage;