import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import EventPage from "./components/events/EventPage";
import Homepage from "./pages/Homepage";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Events from "./pages/Events";
import { Routes, Route, Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Navigation />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:eventId" element={<EventPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
