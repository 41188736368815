import "./topics.css";
import { Col, Row } from "antd";
import TopicCard from "./TopicCard";
import topicEntries from "./topicEntries";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect, useLayoutEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const Topics = () => {
  const comp = useRef();
  const listRef = useRef();

  useLayoutEffect(() => {
    // -- ANIMATION CODE HERE --
    let ctx = gsap.context(() => {
      gsap.set(".topic-card-container", { position: "absolute" });
      gsap.to(".topic-card-container", {
        yPercent: -100,
        stagger: 0.5,
        scrollTrigger: {
          trigger: listRef.current,
          start: "top top",
          end: "+=2000px",
          scrub: true,
          pin: true,
        },
      });
    }, comp); // <- IMPORTANT! Scopes selector text
    return () => ctx.revert(); // cleanup
  }, []); // <- empty dependency Array so it doesn't re-run on every render!

  return (
    <>
      <section id="spezialgebiete" ref={comp} className="homepage-topics padding-xl">
        <div className="section-container">
          <Row className="topics-title-row">
            <Col span={24} className="align-center">
              <h2 className="fs-2 clr-primary-main ff-semibold">
                Meine Spezialgebiete
              </h2>
            </Col>
          </Row>
          <div ref={listRef} className="topics-list topics-cards-list">
            {topicEntries.map(({ id, topicName, subtopics }) => (
              <TopicCard key={id} topicName={topicName} subtopics={subtopics} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Topics;
