import './hero.css'
import { Row, Col } from "antd";
import { HashLink as Link } from 'react-router-hash-link'; 

const Hero = () => {
  return (
    <>
    <section className="homepage-hero">
        <div className="section-container">
          <Row className="homepage-hero-row" gutter={64}>
            <Col className="bubbles-column" xs={24} lg={8}>
              <div className="bubbles-container">
                <div className="inner">
                  <div className="shape"></div>
                  <div className="hello-bubble glass border-radius-l">
                    <div className="hand fs-3">👋</div>
                    <div className="introduction">
                      <p className="ff-light fs-7 lh-heading">Hi, ich bin</p>
                      <p className="ff-semibold fs-3 lh-body">Max.</p>
                      <p className="ff-light fs-7 lh-body">
                        Ich bin selbstständiger Dozent und biete State-of-the-Art{" "}
                        Technology Trainings zu vielen verschiedenen <Link class="hyper" to="/#mein-kursangebot" smooth>Themen</Link> an.
                      </p> 
                    </div>
                  </div>
                  <div className="service-types-bubble glass border-radius-l">
                    <p className="fs-7-fixed lh-body ff-light">Trainings</p>
                    <p className="fs-7-fixed lh-body ff-light">Schulungen</p>
                    <p className="fs-7-fixed lh-body ff-light">Projekte</p>
                    <p className="fs-7-fixed lh-body ff-light">Bootcamps</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="image-column" xs={24} lg={8}>
              <div className="hero-image-container">
                <div className="big-hero-shape"></div>
                <img
                  className="hero-image"
                  src="../img/homepage/hero/max.png"
                  alt=""
                />
              </div>
            </Col>
            <Col className="topics-column" xs={24} lg={8}>
              <div className="topics-container">
                <div className="topic-blurb informatics">
                  <img src="../img/homepage/hero/informatics.webp" alt="" />
                  <span className="fs-7 ff-regular">Informatik</span>
                </div>
                <div className="topic-blurb engineering">
                  <img src="../img/homepage/hero/mechanical_engineering.webp" alt="" />
                  <span className="fs-7 ff-regular">Maschinenbau</span>
                </div>
                <div className="topic-blurb e-tech">
                  <img src="../img/homepage/hero/electrical_engineering.png" alt="" />
                  <span className="fs-7 ff-regular">Elektrotechnik</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default Hero;
