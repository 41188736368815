import { v4 as uuid4 } from "uuid";

const topicEntries = [
    {
        // topic Informatik
        id: uuid4(),
        topicName: "informatik",
        subtopics: [
            {
                id: uuid4(),
                subtopicName: "Mikrokontrollertechnik",
            },
            {
                id: uuid4(),
                subtopicName: "Python für jede Lebenslage",
            },
            {
                id: uuid4(),
                subtopicName: "C-Programmierung",
            },
            {
                id: uuid4(),
                subtopicName: "Netzwerktechnik",
            },
            {
                id: uuid4(),
                subtopicName: "Servertechnologie",
            },
            {
                id: uuid4(),
                subtopicName: "Industrie 4.0",
            },
            {
                id: uuid4(),
                subtopicName: "Digitalisierung",
            }
        ]
    },
    {
        // topic Maschinenbau
        id: uuid4(),
        topicName: "maschinenbau",
        subtopics: [
            {
                id: uuid4(),
                subtopicName: "CAD",
            },
            {
                id: uuid4(),
                subtopicName: "Additive Fertigung",
            },
            {
                id: uuid4(),
                subtopicName: "Alles rund um den 3D Drucker",
            },
            {
                id: uuid4(),
                subtopicName: "Elektro-Pneumatik",
            },
            {
                id: uuid4(),
                subtopicName: "Metallbearbeitung",
            }
        ]
    },
    {
        // topic Elektrotechnik
        id: uuid4(),
        topicName: "elektrotechnik",
        subtopics: [
            {
                id: uuid4(),
                subtopicName: "Sensorik",
            },
            {
                id: uuid4(),
                subtopicName: "Leistungselektronik",
            },
            {
                id: uuid4(),
                subtopicName: "Verdrahtungstechnik",
            },
            {
                id: uuid4(),
                subtopicName: "SPS Programmierung",
            },
            {
                id: uuid4(),
                subtopicName: "Motorentechnik",
            },
            {
                id: uuid4(),
                subtopicName: "Messtechnik",
            },
            {
                id: uuid4(),
                subtopicName: "Anlagentechnik",
            },
            {
                id: uuid4(),
                subtopicName: "Platinenfertigung",
            },
            {
                id: uuid4(),
                subtopicName: "Löten von A bis Z",
            }
        ]
    }
]

export default topicEntries;