import courseEntries from "./courseEntries";
import "./courses.css";
import "../../antStuff.css";
import { Col, Row } from "antd";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import CourseSlide from "./CourseSlide";
import CourseFilters from "./CourseFilters";
import { HashLink as Link } from 'react-router-hash-link'; 

import { useState } from "react";
import NoCourse from "./NoCourse";

const Courses = () => {
  // Main topics state
  const [selectedMainTopic, setSelectedMainTopic] = useState(null);
  // Course types state
  const [selectedCourseType, setSelectedCourseType] = useState(null);
  // filteredCourses logic
  const filteredCourses = courseEntries.filter(
    (course) =>
      (selectedMainTopic === null ||
        selectedMainTopic === undefined ||
        course.mainTopics.includes(selectedMainTopic)) &&
      (selectedCourseType === null ||
        selectedCourseType === undefined ||
        course.courseType.includes(selectedCourseType))
  );

  // conditional className for courses-swiper
  let containerClassName = "";
  if (filteredCourses.length === 2) {
    containerClassName = "has-two-slides";
  } else if (filteredCourses.length === 1) {
    containerClassName = "has-one-slide";
  }

  return (
    <>
      <section className="homepage-courses padding-m" id="mein-kursangebot">
        <div className="section-container">
          <Row className="courses-title-row">
            <Col span={24} className="align-center">
              <h2 style={{marginBottom: "1.5rem"}} className="fs-2 clr-primary-main ff-semibold">
                Mein Kursangebot
              </h2>
            </Col>
            <Col span={24} className="align-center">
              <p className="fs-6 ff-light lh-body">
                Alle Kurse lassen sich an individuelle Anforderungen anpassen.
                Auch eigene Kurse zu verwandten Themen sind möglich, dazu am
                besten eine Anfrage über das{" "}
                <Link class="hyper" to="/#kontakt" smooth>
                  Kontaktformular
                </Link>{" "}
                senden. Klicken Sie einfach auf "Details ansehen" unter dem
                jeweiligen Kurs der Sie interessiert, um weitere Informationen
                und mögliche Voraussetzungen zu erhalten. Es gibt Kurse die als
                klassische Schulungen angeboten werden, aber es besteht auch die
                Möglichkeit einige Themen als sogenanntes Bootcamp
                durchzuführen. Dies bietet sich besonders für Gruppen an, die
                sich als Team einer praxisnahen Herausforderung stellen wollen.
              </p>
            </Col>
          </Row>

          <Row className="courses-filter-row">
            <Col span={24} className="align-center">
              <CourseFilters
                onSelectMainTopic={setSelectedMainTopic}
                selectedMainTopic={selectedMainTopic}
                setSelectedMainTopic={setSelectedMainTopic}
                onSelectCourseType={setSelectedCourseType}
                selectedCourseType={selectedCourseType}
                setSelectedCourseType={setSelectedCourseType}
              />
            </Col>
          </Row>
          <Swiper
            className={`courses-swiper ${containerClassName}`}
            navigation={true}
            modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1300: {
                slidesPerView: 3,
                spaceBetween: 64,
              },
            }}
          >
            {filteredCourses.map((course) => (
              <SwiperSlide className="course-swiper-slide" key={course.id}>
                <CourseSlide
                  // placement here
                  key={course.id}
                  courseType={course.courseType}
                  mainTopics={course.mainTopics}
                  courseTitle={course.courseTitle}
                  courseSubtitle={course.courseSubtitle}
                  imgName={course.imgName}
                  badges={course.badges}
                  tabs={course.tabs}
                ></CourseSlide>
              </SwiperSlide>
            ))}
            {filteredCourses.length === 0 && <NoCourse />}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Courses;
