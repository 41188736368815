import eventsEntries from "./eventsEntries";
import React from "react";
import EventItem from "./EventItem";
import "./eventList.css";

const EventList = () => {
  return (
    <>
      <ul className="event-list">
        {eventsEntries.map((event) => (
          <li key={event.eventId}>
            <EventItem
              categories={event.categories}
              eventId={event.eventId}
              title={event.title}
              featuredImage={event.featuredImage}
              excerpt={event.excerpt}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default EventList;
