import './badge.css';

const Badge = ( {id, name, color} ) => {
  return (
    <>
      <div className="badge" style={{backgroundColor: color}}>
        <span className="fs-8 ff-light" key={id}>
          {name}
        </span>
      </div>
    </>
  );
};

export default Badge;