import { v4 as uuid4 } from "uuid";

const eventsEntries = [
  {
    eventId: "piandmore13-summary",
    title: "Das war die Pi and More 13",
    date: "22. April 2023",
    author: "Max Herchet",
    featuredImage: {
      src: "../../img/events/piandmore.png",
      alt: "Featured Image",
    },
    categories: [
      {
        id: uuid4(),
        name: "Messe",
        color: "#047583",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#984526",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#119536",
      },
      
    ],
    excerpt:
      "Am Samstag den 22. April 2023 fand die Pi and More 13 statt. Es war eine tolle Atmosphäre und es gab einige interessante Gespräche und Anregungen für unser Spotwelder Projekt.",
    content: [
      {
        title:
          "Re­sü­mee: Pi and More 13",
        elements: [
        {
            type: "image",
            value: {
              src: "../../img/events/spotwelder2.webp",
              alt: "Image 2",
            },
            },
          {
            type: "paragraph",
            value:
              "Am Samstag den 22. April 2023 fand die Pi and More 13 statt. Es war eine tolle Atmosphäre und es gab einige interessante Gespräche und Anregungen für unser Spotwelder Projekt.",
          },
        ],
      },
      {
        title:
          "Wie geht es weiter?",
        elements: [
        {
            type: "image",
            value: {
              src: "../../img/events/motorcycle-16-9.webp",
              alt: "Image 2",
            },
            },
          {
            type: "paragraph",
            value:
              "Nun stellt sich die Frage wie es weiter geht. Den Spotwelder haben wir nicht nur aus Spaß gebaut. Es wird Zeit, dass dieser produktiv zum Einsatz kommt. Wir werden also für das kommende Projekt ein Batteriepack benötigen und dieses selbst bauen. Mit einigen Gästen der Pi and More 13 hatten wir das Thema schon angerissen, für alle Anderen: schaut ab und an mal vorbei um zu erfahren, wie es mit unserem Projekt aus dem Bereich der Elektromobilität weiter geht.",
          },
        ],
      },
    ],
  },
  {
    eventId: "piandmore13",
    title: "Pi and More 13",
    date: "22. April 2023",
    author: "Max Herchet",
    featuredImage: {
      src: "../../img/events/piandmore.png",
      alt: "Featured Image",
    },
    categories: [
      {
        id: uuid4(),
        name: "Messe",
        color: "#047583",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#984526",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#119536",
      },
      
    ],
    excerpt:
      "Am Samstag den 22. April 2023 findet die Pi and More 13, die größte deutsche Community Veranstaltung rund um Mikroprozessoren und Bastelprojekt an der Hochschule Trier statt. Dieses Jahr bin auch ich dort mit einem Projekt vertreten und werde zusammen mit meinem Kollegen Dr. Raphael Fischer von Fischer Competences (www.fischer-competences.com) einen Vortrag halten.",
    content: [
      {
        title:
          "Basteln von Bastlern für Bastler",
        elements: [
          {
            type: "paragraph",
            value:
              "Am Samstag den 22. April 2023 findet die Pi and More 13, die größte deutsche Community Veranstaltung rund um Mikroprozessoren und Bastelprojekt an der Hochschule Trier statt. Dieses Jahr bin auch ich dort mit einem Projekt vertreten und werde zusammen mit meinem Kollegen Dr. Raphael Fischer von Fischer Competences (www.fischer-competences.com) einen Vortrag halten.",
          },
        ],
      },
      {
        title: "Der Spotwelder oder was für ein Werkzeug braucht man zur Batteriepackherstellung",
        elements: [
          {
            type: "image",
            value: {
              src: "../../img/events/spotwelder.webp",
              alt: "Image 2",
            },
            },
            {
            type: "paragraph",
            value:
              "Batterien. Ob in unseren Smartphones, Akkuschrauber oder E-Bike, Batterien sind überall. Aber was benötigen wir überhaupt um ein Batteriepack zu bauen? Erleben Sie in diesem spannenden Vortrag welches Werkzeug zum Herstellen von Batteriepaketen aus einzelnen Batteriezellen notwendig ist und wie die wesentlichen Komponenten eines sogenannten \"Spotwelders\" funktionieren. Bei dem Vortrag werden die interdisziplinären Herausforderungen des Projekts inklusive der praktischen Umsetzung gezeigt. Die zwei Experten führen Schritt für Schritt durch die Entwicklung und den Zusammenbau des Schweißgerätes und gehen dabei sowohl auf die Mechanik, die Elektrik und die Programmierung ein. Neben der Umsetzung wird \"kurz und knackig\" auf die zugrundeliegende Physik und Mikrocontrollerunterstützung zur Einhaltung der elektrischen Parameter eingegangen. Diesen Vortrag solltest du nicht verpassen! Unser Vortrag geht von 16:00 Uhr bis 16:25 Uhr und findet im Rev.Pi Hub (HS3) statt.",
          },
          
        ],
      },
    ],
  },
];

export default eventsEntries;
