import "./courseDetails.css";
import Badge from "./Badge";
import { Tabs, Row, Col } from "antd";
import { HashLink as Link } from 'react-router-hash-link'; 
// import { Components } from "antd/es/date-picker/generatePicker";

const { TabPane } = Tabs;

const CourseDetails = ({ courseTitle, courseSubtitle, badges, tabs, onCloseModal }) => {
  return (
    <>
      <div className="course-details-container">
        <div className="title-container">
          <h3 className="lh-heading ff-medium fs-5">{courseTitle}</h3>
          <p className="lh-heading ff-light fs-6">{courseSubtitle}</p>
        </div>
        <div className="badges-container mg-horizontal-16 mg-vertical-16">
          {badges.map((badge) => (
            <Badge key={badge.id} name={badge.name} color={badge.color} />
          ))}
        </div>
        <Tabs defaultActiveKey="1">
          {tabs.map((tab) => (
            <TabPane tab={tab.label} key={tab.key}>
              <div className="tab-contents mg-vertical-48">
                <div className="learning-outcomes mg-vertical-16">
                  <h4 className="ff-medium fs-5">Was Sie lernen werden</h4>
                  <ul className="learnings">
                    <Row
                      gutter={{ xs: 0, lg: 32 }}
                      className="learnings-row mg-vertical-16"
                    >
                      {tab.children.learningOutcomes.map((outcome) => (
                        <Col xs={24} lg={12}>
                          <li
                            className="learning-item fs-7 ff-light"
                            key={outcome}
                          >
                            ✓ {outcome}
                          </li>
                        </Col>
                      ))}
                    </Row>
                  </ul>
                </div>
                <div className="requirements mg-vertical-16">
                  <h4 className="ff-medium fs-5">Anforderungen</h4>
                  <p className="fs-7 ff-light lh-body">
                    {tab.children.requirements}
                  </p>
                </div>
                <div className="duration mg-vertical-16">
                  <h4 className="ff-medium fs-5">Kursdauer</h4>
                  <p className="fs-7 ff-light lh-body">
                    {tab.children.duration}
                  </p>
                </div>
                <div className="description mg-vertical-16">
                  <h4 className="ff-medium fs-5">Beschreibung</h4>
                  <p className="fs-7 ff-light lh-xl">
                    {tab.children.description}
                  </p>
                </div>
                <Link
                  onClick={onCloseModal}
                  to="./#kontakt"
                  className="btn btn-regular course-request"
                  smooth
                >
                  <span className="price-request">{tab.children.price}</span>
                  Jetzt&nbsp;anfragen
                </Link>
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default CourseDetails;
