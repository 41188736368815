import { useState } from "react";
import { Popover, Modal } from "antd";
import CourseDetails from "./CourseDetails";
import "./courseSlide.css";

// const courseDetails = <CourseDetails courseTitle={courseTitle} />;

const CourseSlide = ({
  id,
  courseTitle,
  courseSubtitle,
  imgName,
  badges,
  tabs,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // for closing the Modal from within CourseDetails
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="course-slide-container">
      <img
        className="course-image"
        src={"../img/homepage/courses/" + imgName + ".webp"}
        alt={courseTitle}
      />
      <div className="course-slide-title-box glass mg-vertical-4">
        <h4 className="fs-6 ff-medium">{courseTitle}</h4>
        <p className="fs-8-fixed ff-light lh-heading">{courseSubtitle}</p>
      </div>
      <span
        onClick={showModal}
        className="btn btn-regular course-btn clr-white"
      >
        Details&nbsp;ansehen
      </span>
      <Modal
        footer={false}
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CourseDetails
          key={id}
          courseTitle={courseTitle}
          courseSubtitle={courseSubtitle}
          badges={badges}
          tabs={tabs}
          onCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default CourseSlide;
