//do not touch this file

import courseEntries from "./courseEntries";

const tempObj = {};

// loop through each courseEntry
courseEntries.forEach(courseEntry => {
  // loop through each mainTopic in mainTopics array
  courseEntry.mainTopics.forEach(mainTopic => {
    if (tempObj[mainTopic]) {
      // if mainTopic already exists, push courseEntry to corresponding array
      tempObj[mainTopic].push(courseEntry);
    } else {
      // if mainTopic doesn't exist, create new key-value pair
      tempObj[mainTopic] = [courseEntry];
    }
  });
});

const courseHierarchy = [];

// loop through each key in tempObj
Object.keys(tempObj).forEach(mainTopic => {
  // create new object with mainTopic and corresponding array value
  const obj = {
    [mainTopic]: tempObj[mainTopic]
  };
  // push new object to courseHierarchy array
  courseHierarchy.push(obj);
});

// courseHierarchy array contains one object per unique mainTopic, with an array of courses for each mainTopic

export default courseHierarchy;