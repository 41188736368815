import { Row, Col } from "antd";
import "./topicCard.css";
import { HashLink as Link } from 'react-router-hash-link'; 

const TopicCard = ({ topicName, subtopics }) => {
  return (
    <>
      <Row className="topic-card-container">
        <Col xs={24} lg={20} className="topic-card glass">
          <Row className="topic-card-container-row">
            <Col xs={{span: 24, order: 2}} md={{span: 12, order: 1}} className="topic-card-text-column">
              <div className="mg-vertical-32">
                <h3 className="fs-2 ff-medium topic-title clr-primary-light">
                  {topicName}
                </h3>
                <ul className="topics-list">
                  {subtopics.map(({ id, subtopicName }) => (
                    <li className="topics-list-item fs-6-fixed ff-light" key={id}>
                      {subtopicName}
                    </li>
                  ))}
                </ul>
                <Link className="btn btn-regular" to="/#mein-kursangebot" smooth>
                  Zu den Kursen
                </Link>
              </div>
            </Col>
            <Col xs={{span: 24, order: 1}} md={{span: 12, order: 2}} className="topic-card-image-column">
              <div className="container">
                <img
                  src={"../img/homepage/topics/" + topicName + ".png"}
                  alt=""
                  className="topic-image"
                />
                <div className="overlay"></div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TopicCard;
