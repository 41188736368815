import React from "react";
import EventItemImage from "./EventItemImage";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Badge from "../homepage/courses/Badge";
import "./eventItem.css";

const EventItem = ({ eventId, title, featuredImage, excerpt, categories }) => {
  return (
    <>
      <div key={eventId}>
        <Row className="event-item-row glass border-radius-m">
          <Col xs={24} lg={11} className="event-item-img-col">
            <EventItemImage src={featuredImage.src} alt={featuredImage.alt} />
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
            className="event-item-text-col mg-vertical-16"
          >
            {/* <p className="ff-light fs-8-fixed clr-white lh-body">
              {categories}
            </p> */}
            <div className="categories mg-horizontal-8 mg-vertical-4">
              {categories.map((category) => (
                <Badge
                  key={category.id}
                  id={category.id}
                  name={category.name}
                  color={category.color}
                />
              ))}
            </div>
            <h2 className="ff-semibold fs-3 clr-white">{title}</h2>
            <p className="ff-light fs-6-fixed clr-white lh-body">{excerpt}</p>
            <Link
              className="learn-more-btn btn btn-flat"
              to={`/events/${eventId}`}
            >
              Mehr erfahren
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EventItem;
