import React from "react";
import EventList from "../components/events/EventList";
import './events.css';

const Events = () => {
  return (
    <>
      <section className="events">
        <div className="section-container">
          <div className="align-center">
            <h1 className="fs-1 ff-semibold clr-primary-main">Events</h1>
          </div>
          <div className="events-grid">
            <EventList />
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
