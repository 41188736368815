import { v4 as uuid4 } from "uuid";

const courseEntries = [
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "3D-Druck und Additive Fertigung",
    courseTitle: "3D-Druck und Additive Fertigung",
    courseSubtitle: "Zerspanung ist nicht alles",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "additive-manufacturing",
    mainTopics: ["Maschinenbau", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "CAD",
        color: "#C1121F",
      },
      {
        id: uuid4(),
        name: "Sensorik",
        color: "#FD8A09",
      },
      {
        id: uuid4(),
        name: "Mikrocontroller",
        color: "#C11CAD",
      },
      {
        id: uuid4(),
        name: "Additive Fertigung",
        color: "#3A0CA3",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Grundprinzipien eines 3D Druckers",
            "Umgang mit FDM Druckern",
            "Druck-Vorbereitung und -Durchführung",
            "Druckmaterialien",
            "Eigenes Modell suchen und drucken",
            "Nachbearbeitung"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Dieser Kurs richtet sich an Leute wie dich, die in die Welt der 3D-Drucktechnologie einsteigen möchten. In diesem Kurs lernst du die Grundlagen der additiven Fertigung kennen, einschließlich der verschiedenen Druckverfahren, Materialien und Anwendungen. Du wirst erfahren, wie man 3D-Modelle für den Druck vorbereitet und diese schließlich an den 3D-Drucker übermittelt. Darüber hinaus wirst du lernen, wie man 3D-Drucker bedient und wartet, um qualitativ hochwertige und zuverlässige Ergebnisse zu erzielen. Im Laufe des Kurses wirst du die verschiedenen Materialien für die additive Fertigung kennenlernen, wie Kunststoffe, Metalle und Keramiken, sowie deren jeweiligen Anwendungen und Eigenschaften. Du wirst auch lernen, wie man 3D-Drucke nachbearbeitet und ihnen den letzten Schliff gibt. Am Ende des Kurses wirst du in der Lage sein, deine 3D-Modelle zu drucken, sowie die verschiedenen Materialien und Anwendungen der additiven Fertigung zu verstehen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "FDM Druck Optimierung",
            "Umgang mit SLA Druckern",
            "SLA Druck Optimierung",
            "Druck-Vorbereitung und -Durchführung",
            "Fehlerbilder erkennen",
            "Eigenes Modell in verschiedenen Materialien drucken",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Dieser Kurs ist für Personen gedacht, die ihr Wissen in der additiven Fertigung vertiefen wollen und ihre Fähigkeiten erweitern möchten. In diesem Kurs lernst du Techniken zur Optimierung von 3D-Druck Designs kennen. Der Kurs beginnt mit einer detaillierten Betrachtung der Materialien und ihrer Eigenschaften, damit du die optimale Auswahl für verschiedene Anwendungen treffen kannst. Du wirst lernen, wie man die Druckparameter auf der Basis von Material- und Konstruktionsanforderungen optimiert, um die Druckqualität zu verbessern und die Druckzeit zu reduzieren. Im weiteren Verlauf des Kurses wirst du fortgeschrittene Themen wie das Erstellen und Optimieren von Unterstützungsstrukturen, das Entwerfen und Drucken von komplexen Geometrien, das Hinzufügen von Farben und Texturen sowie das Nachbearbeiten von Drucken behandeln. Darüber hinaus wirst du lernen, wie man Fehler bei der Fertigung diagnostiziert und behebt und wie man additive Fertigung in einem industriellen Umfeld einsetzt. Im Laufe des Kurses wirst du verschiedene Testobjekte drucken, um die Eigenschaften nachvollziehen zu können. Am Ende des Kurses wirst du in der Lage sein, Druckprozesse anhand der Materialienauswahl und Druckparamtern zu optimieren und Probleme bei der Fertigung zu lösen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Umgang mit FDM Drucker",
            "CAD Modellierung",
            "Fertigungsgerechtes Konstruieren",
            "Dateiformate verstehen",
            "Druck-Vorbereitung und -Durchführung",
            "Umgang mit der Maschine",
            "Eigenes Modell konstruieren und drucken",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "In diesem Kurs wirst du an realen Projekten arbeiten und maßgeschneiderte Teile und Prototypen fertigen, um dein Wissen und deine Fähigkeiten in der additiven Fertigung zu vertiefen. Am Ende des Kurses wirst du in der Lage sein, komplexe Designs zu erstellen und zu fertigen, Materialien zu optimieren und Probleme bei der Fertigung zu vermeiden.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Nextcloud",
    courseTitle: "Nextcloud",
    courseSubtitle: "Eine moderne und selbstverwaltete Cloud",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "cloud",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Verwendung von Nextcloud",
            "Einrichtung einer persönlichen Cloud",
            "Verbindung und Synchronisation von (mobilen) Endgeräten",
            "Automatische Datensicherung",
            "Teilen von Daten",
            "Verwaltung eines eigenen Kalenders",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Nextcloud ist eine quelloffene Plattform zur Freigabe von Daten und der Zusammenarbeit. Gerade durch die offene Implementierung bietet Nextcloud interessante Möglichkeiten sowohl für private als auch industrielle Anwendungen. In diesem Kurs lernst du die Nutzung von Nextcloud über die Weboberfläche und die mobile App kennen. Nach der Einführung lernst Du, wie ein Nextcloud Konto erstellt wird und wie man sich anmeldet. Im Laufe des Kurses wirst Du lernen, wie man Dateien hochlädt und verwaltet, Dateien und Ordner mit anderen teilet, in Echtzeit an Dokumenten zusammenarbeitet und Daten geräteübergreifend synchronisiert. Sie werden auch lernen, wie Sie die in Nextcloud integrierten Kommunikationsfunktionen nutzen können, einschließlich der Chat- und Videokonferenz-Tools. Darüber hinaus behandelt der Kurs Themen wie Sicherheit, Datenschutz und Datensicherung, damit du mit den gängigen Prinzipien von Cloudspeichern vertraut bist. Am Ende des Kurses wirst Du ein solides Verständnis dafür haben, wie man Nextcloud für persönliche und berufliche Bedürfnisse nutzen kann. Egal, ob Du als Unternehmer die Zusammenarbeit im Team verbessern willst oder als Privatperson eine Alternative zu den gängigen Cloud Speicherlösungen suchst, dieser Kurs wird Dir das Wissen und die Fähigkeiten vermitteln, die Du für den Einstieg in Nextcloud benötigst.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Verwendung von Nextcloud in einer Projektumgebung",
			"Nextcloud als Projektplattform verstehen",
			"Möglichkeiten der Integration identifizieren",
			"Produktivitätssteigerung durch eine Plattform",
            "Kollaboration und Videokonferenzen",
          ],
          requirements:
            "Vorkenntnisse in der Bedienung von Nextcloud.",
          duration: "1 Tage",
          description:
            "Der Advance Kurs für Nextcloud ist für Personen konzipiert, die bereits Erfahrung in der Nutzung von Nextcloud haben und ihr Wissen auf die nächste Stufe bringen möchten. In diesem Kurs lernst Du, wie man die Funktionalität der Plattform anpasst, erweitert und andere Systeme integriert, um Nextcloud für mehrere Nutzer und Nutzergruppen zu verwalten. Der Kurs beginnt mit einem Überblick über fortgeschrittene Nextcloud-Konzepte, einschließlich Anpassung, Entwicklung und Skalierbarkeit. Du lernst, wie man die Benutzeroberfläche anpasst, neue Funktionen durch Apps und Plugins hinzufügt und Nextcloud mit externen Systemen über APIs und Webhooks integrieren kann. Im weiteren Verlauf lernst Du die verschiedenen Bereitstellungsmöglichkeiten von Nextcloud kennen, wie z.B. die Installation auf dem eigenen Server oder die Nutzung eines Cloud-Anbieters. Zusätzlich wird der Kurs Themen wie Sicherheit, Compliance und Datenverwaltung abdecken, um sicherzustellen, dass Du mit den bewährten Praktiken vertraut bist, die bei der Verwendung von Nextcloud in einer Mehrnutzerumgebung erforderlich sind. Am Ende des Kurses hast Du ein tieferes Verständnis für die Architektur und Fähigkeiten von Nextcloud und kannst das fortgeschrittene Wissen nutzen, um die Plattform an eigene Bedürfnisse anzupassen, zu erweitern und bereitzustellen. Egal, ob Du Entwickler, Systemadministrator oder IT-Enthusiast bist, dieser Kurs stattet dich mit dem Wissen und den Fähigkeiten aus, die Du benötigen, um ein Nextcloud produktiv nutzen und verwalten zu können.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Nextcloud Server Setup und erweiterte Konfiguration",
			"Einen Linux Server aufsetzen",
            "Nextcloud auf einem Server installieren",
            "Backup Konzepte im Zusammenhang mit Nextcloud lernen",
            "Systemverwaltung auf Root Ebene"
          ],
          requirements:
            "Sicherer Umgang in der Bedienung von Nextcloud.",
          duration: "1 Tage",
          description:
            "Die letzte Stufe des Nextcloud Kurses wendet sich an Anwender, die zum Systemadminsitrator werden wollen. Im weiteren Verlauf des Kurses tauchst Du tiefer in das Backend von Nextcloud ein und lernst, wie der Server konfiguriert wird, die Leistung optimiert und Probleme behoben werden. Es werden fortgeschrittene Themen wie Hochverfügbarkeit, Lastausgleich und Datenreplikation untersucht. Dabei liegt das Augenmerk auf dem System \"Nextcloud\" in einer Serverumgebung. Es wird ein eigener Testserver aufgesetzt und konfiguriert. Dabei wird auf das Backend inklusive der Datenbankstruktur eingegangen. Ziel dieses Kurses ist es Nextcloud nicht nur zu bedienen, sondern in der Lage zu sein eine Nextcloud Instanz zu \"deployen\" und an eigene Anforderungen anzupassen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Sensorik",
    courseTitle: "Sensorik",
    courseSubtitle: "Messen und Erfassen",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "sensor",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Grundlagen",
        children: {
          learningOutcomes: [
            "Abstandssensorik verstehen",
            "Sensorarten",
            "Sensorwirkprinzip",
            "Induktive und kapatzitive Sensoren",
            "Optische Sensoren",
            "Akustische Sensoren",
            "Anwendung von Sensoren",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "7 Tage",
          description:
            "Sensoren sind in vielen alltäglichen Bereichen unseres Lebens zu finden. In diesem Kurs werden Grundprinzipien von gängigen Sensoren erkundet, um die Wirkprinzipien besser nachvollziehen zu können. Es werden anhand von Beispielen und praktischen Übungen verschiedene Sensortypen untersucht und auf deren Eignung für spezifische Aufgabenbereiche bewertet.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Mikrocontroller",
    courseTitle: "Mikrocontroller",
    courseSubtitle: "Kleine Computer mit viel Potential",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "microcontroller",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Kennenlernen des Aufbaus eines Mikrocontrollers",
            "Programmieren in vereinfachtem C/C++",
            "Nutzen von digitalen Ein- und Ausgängen",
            "Nutzen von analogen Ein- und Ausgängen",
            "Entwurf und Programmierung einer Steuerung"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "In diesem Kurs werden die Grundkenntnisse der Mikrocontroller anhander der offenen Arduino Plattform vermittelt. Der Einstieg fällt hierbei besonders leicht, da keinerlei Vorkenntnisse notwendig sind um bereits nach kürzester Zeit Erfolge im Zusammenhang mit der Programmierung zu erkennen sind. Dieser Kurs dient dem ersten Konntakt mit der Materie der Mikrocontroller und beinhaltet kleine Übungen, die zum Verständis des Systems helfen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Kennenlernen des Aufbaus eines Mikrocontrollers",
            "Programmieren in klassischem C/C++",
            "Nutzen von digitalen Ein- und Ausgängen",
            "Nutzen von analogen Ein- und Ausgängen",
            "Entwurf und Programmierung einer Steuerung",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich, Vorkenntnisse in anderen Programmiersprachen sind hilfreich.",
          duration: "3 Tage",
          description:
            "Wer bereits die generelle Logik zum Erstellen von Programmen beherrscht und noch C/C++ im embedded Umfeld erlernen möchte, sollte diesen Kurs wählen. Anders als im Beginner Kurs wird hier klassisches C/C++ ohne Vereinfachungen programmiert. Dies bieten tiefere Einblicke in die Materie und ermöglicht ein besseres Verständnis für eingebettete System.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Realisiserung eines Embedded Systems nach eigenen Vorstellungen",
            "Umsetzung eines selbst gewählten Programmierprojekts"
          ],
          requirements:
            "C/C++ Grundkenntnisse",
          duration: "3 Tage",
          description:
            "Wer gerne zusammen mit anderen programmiert und Projekte realisiert, ist hier genau richtig. Also ein Kurs für echte Macher und Macherinnen! Sie wählen ein eigenes Thema aus dem Bereich der eingebetteten Systemen wie z.B. IoT Sensoren für den Heimbereich, Elektromotorsteuerungen, etc. und erhalten professionelle Unterstützung bei der Realisierung des Projekts. Falls Sie keine Thema wissen, aber dennoch gerne Basteln, berate ich Sie gerne.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Python Grundkurs",
    courseTitle: "Python Grundkurs",
    courseSubtitle: "Programmieren lernen",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "python",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Grundlagen",
        children: {
          learningOutcomes: [
            "Grundlagen Python lernen",
            "Automatisierung von sich wiederholenden Aufgaben",
            "Steigerung der Produktivität in verschiedenen Bereichen",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Python ist sehr vielseitig einsetzbar. So ist diese Hochsprache zum Einen gut geeignet um den Einstieg in die Programmierung zu wagen. Zum Anderen lassen sich mit ihr beispielsweise komplexe Aufgaben aus den Bereichen Statistik, Künstliche Intelligenz und Naturwissenschaften lösen. Generell lassen sich repetitive Aufgaben automatisieren um Zeit zu sparen und die Produktivität zu steigern. Egal ob Sie einen Start in die Welt des Programmierens suchen oder neue Herausforderungen programmtechnisch lösen wollen, dieser Kurs bietet für jeden Wissensstand interessante Anwendungen und Übungen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "SPS Programmierung",
    courseTitle: "SPS Programmierung",
    courseSubtitle: "Programmieren mit TIA und  S7",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "sps",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Hardware der SPS kennenlernen",
            "Hardwarekonfiguration",
            "Logikverknüpfungen",
            "Programmieren mit Logikbausteinen",
            "Automatisierung",
            "Programmieren mit dem Funktionsplan - FUP"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "7 Tage",
          description:
            "Der Beginner Kurs richtet sich an Teilnehmer, welche die Grundlagen der Logik und Steuerungen erlernen möchten. Dazu gehört die Einrichtung der Programmierumgebung sowie die Hardwarekonfiguration. Mit einfachen Programmierbeispielen wird langsam an das Thema herangerführt, was bis zur ersten kleinen Automatisierungslösung führt. Das Zusammenspiel zwischen Hard- und Software wird anschaulich erklärt und erleichtert so die ersten Schritte in der Welt der Automatisierung.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Human Machine Interface (HMI) kennenlernen",
            "Programmstruktur anwenden",
            "Fehler suchen und beheben",
            "Hilfswerkzeuge zur Optimierung kennenlernen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "7 Tage",
          description:
            "Dieser Kurs gibt iefere Einblicke in die Programmierung von speicherprogrammierbaren Steuerungen. Durch Übungen erlernt der Teilnehmer, wie man komplexe Steuerungslogik entwirft, fortgeschrittene Programmierstrukturen verwendet, Fehlerdiagnose und Debugging durchführt und die Netzwerkkommunikation optimiert.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
          	"Erarbeiten eines gesetzten Projektziels",
          	"Erweiterte Programmstrukturierung",
            "Structured Control Language - SCL",
          ],
          requirements:
            "Grundkenntnisse der SPS Logik und der FUP Programmiersprache",
          duration: "7 Tage",
          description:
            "Die Umsetzung eines Automatisierungsprojektes anhand von praxisorientierten Themen erfordert die Umsetzung bereits erlernter Zusammenhänge anhand von realen Problemstellungen. Zusätzlich besteht die Möglichkeit die \"Structured Control Language\" zu erlernen, um komplexe Berechnungen durchführen zu können.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
    {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Netzwerktechnik",
    courseTitle: "Netzwerktechnik",
    courseSubtitle: "Kommunikation über Ethernet",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "netzwerk",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Aufbau von Netzwerken",
            "Der Ethernet Standard",
            "Kommunikation",
            "Adressierung",
            "Netzwerkkonfiguration",
            "IT-Sicherheit"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "In dieser Stufe werden Sie die Grundlagen der Netzwerktechnologie erlernen. Ziele sind das Wissen über den Aufbau von Netzwerken, wie Daten durch ein Netzwerk fließen und wie Netzwerke kommunizieren. Zusätzlich werden Sie die Grundlagen von Netzwerkprotokollen, IP-Adressierung, Subnetting und Netzwerksicherheit kennenlernen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Netzwerktopologien",
            "Switching",
            "Routing",
            "Implementierung von Netzwerksicherheit"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "In der nächsten Stufe werden Sie tiefer in die Netzwerktechnologie eintauchen. Sie werden lernen, wie Netzwerktopologien aufgebaut sind, wie Routing und Switching funktionieren, wie man ein Netzwerk designet und wie man Netzwerkleistung optimiert. Zusätzlich werden Sie sich mit fortgeschrittenen Netzwerkthemen wie Netzwerksicherheit und Netzwerküberwachung befassen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Projektumsetzung mit realen Problemstellungen",
            "Entwurf und Planung von komplexen Netzwerken"
          ],
          requirements:
            "Grundlegende Kenntnisse der Netzwerktheorie",
          duration: "1 Tage",
          description:
            "In der letzten Stufe wird der Entwurf, der Aufbau und die Verwaltung von komplexe Netzwerke anhand von einem realistischen Projekt behandelt. Zusätzlich zur Problembehebung gibt es die Möglichkeit ein Netzwerk ganz nach eigenen Vorstellung aufzubauen und zu verwalten.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "PCB Design",
    courseTitle: "PCB Design",
    courseSubtitle: "Platinenentwurf mit KiCad",
    // Bild mit diesem Namen und der Endung `.png` im Ordner `img/homepage/courses` speichern
    imgName: "pcb",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Umgang mit Open Source PCB Design Software",
            "Schaltpläne zeichnen und verstehen",
            "Platinenlayouts aus Schaltplänen ableiten"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Dieser Kurs ist für Personen konzipiert, die neu in der Welt der Elektronik sind und noch keine Erfahrung mit PCB Design oder Elektronik haben. In diesem Kurs lernst Du den Umgang mit elektrischen Schaltungen, wie man Schaltpläne erstellt und schließlich ein Platine entwirft und layoutet.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Optimierung des Designs",
            "Bauteilbibliotheken",
            "Fertigung von Platinen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Der Advanced Kurs richtet sich an Personen, die bereits Erfahrung im mit Elektronik gesammelt haben und ihre Fähigkeiten um das PCD Design erweitern möchten. Die Optimierung und Fehlersuche von Platinen Layouts, sowie der erweiterte Umgang mit KiCad sind Bestandteil des Kurses. Dabei lernen die Teilnehmer, wie man benutzerdefinierte Bibliotheken erstellt und Designs für die Fertigung vorbereitest.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "fortgeschrittenes Routing",
            "Hochgeschwindigkeitsanwendungen",
            "fertigungsgerechtes Design",
            "Mehrschichtdesigns"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tage",
          description:
            "Die Profi Stufe richtet sich an Personen, die bereits Erfahrung im PCB Design haben und zu Experten in KiCad werden möchten. Lernziele sind fortgeschrittene Techniken für Routing und Layout, wie das Design für Hochgeschwindigkeitsanwendungen, fertigungsgerechtes Design und komplexe Mehrschichtdesigns.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  
  
];

export default courseEntries;
