import React from "react";
import "./legal.css";

const Impressum = () => {
  return (
    <>
      <section className="legal">
        <div className="section-container">
          <h1 className="fs-1 ff-semibold">Impressum</h1>

          <h2 className="fs-2 ff-medium">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p className="lh-body fs-6-fixed ff-ligFht">
            Max Herchet
            <br />
            Hauptstra&szlig;e 76
            <br />
            91054 Erlangen
          </p>

          <h2 className="fs-2 ff-medium">Kontakt</h2>
          <p className="lh-body fs-6-fixed ff-light">
            Telefon: +49 (0) 9131 933 05 77
            <br />
            E-Mail: info@letspower.it
          </p>

          <h2 className="fs-2 ff-medium">Umsatzsteuer-ID</h2>
          <p className="lh-body fs-6-fixed ff-light">
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
            Umsatzsteuergesetz:
            <br />
            DE 328 965 736
          </p>

          <h2 className="fs-2 ff-medium">Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
          <p className="lh-body fs-6-fixed ff-light">
            <strong>Name und Sitz des Versicherers:</strong>
            <br />
            Zürich Beteiligungs-Aktiengesellschaft (Deutschland)
            <br />
            Platz der Einheit 2 <br />
			60327 Frankfurt am Main
			<br />
			<br />
          </p>
          <p className="lh-body fs-6-fixed ff-light">
            <strong>Geltungsraum der Versicherung:</strong>
            <br />
            Deutschland
          </p>

          <h2 className="fs-2 ff-medium">EU-Streitschlichtung</h2>
          <p className="lh-body fs-6-fixed ff-light">
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h2 className="fs-2 ff-medium">
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p className="lh-body fs-6-fixed ff-light">
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>
      </section>
    </>
  );
};

export default Impressum;
